<template>
    <div id="app">
        <div id="map"></div>
        <div class="top-search-box" style="top: 10px;">
            <span class="top-search-input" @click="showSearch">
                <span class="ant-input-prefix">
                    <img width="15px" src="./assets/find.png" alt="">
                </span>
                <input placeholder="搜索" class="ant-input" type="text" readonly="readonly" value="">
            </span>
        </div>
        <div class="tiyan vertical" @click="showTip">试运行版本</div>
        <transition name="fade">
            <div class="search-area" v-show="isSearch">
                <div style="top: 10px" class="top-search-box">
                    <span style="border: 1px solid #F39640" class="top-search-input">
                        <span class="ant-input-prefix">
                            <img width="15px" src="./assets/arrow-left-bold.png" alt="" @click="hideSearch">
                        </span>
                        <input ref="search" placeholder="请输入关键词搜索" class="ant-input" type="text" v-model="content" @input="startFind">
                    </span>
                </div>
                <div class="search-list">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane v-for="(item,i) in searchData" :key="i" :label="item.typeName" :name="i+''">
                            <div v-if="item.mapPointList.length > 0">
                                <div v-for="(item1,i1) in item.mapPointList" :key="i1" class="search-item">
                                    <div class="search-left">
                                        <div class="search-name">
                                            <img :src="item.photoAfter" alt="">
                                            <div>
                                                <div>{{item1.mapPointName}}</div>
                                                <div class="distance" v-if="item1.distance">距离{{item1.distance}}km</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div :style="'border: 1px solid '+item.themeColor" class="search-btn" @click="showContent1(item1.mapPointId,item1.parkId)">查看详情</div>
                                </div>
                            </div>
                            <div v-else>
                                <el-empty :image-size="100"></el-empty>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </transition>
        <transition name="slide-bottom-fade">
            <div class="map-toolbar" v-show="!isCon">
                <div class="toolbar-bottom">
                    <div class="toolbar-content">
                        <div class="toolbar-item" v-for="(item,i) in markerData" :key="i"
                             :style="'color:'+(active==i?item.themeColor:defaultColor)" @click="selectSort(i)">
                            <img :src="active==i?item.photoAfter:item.photoBefore" alt="">
                            <p>{{item.typeName}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <ul class="side side-right">
            <li style="margin-bottom: 10px" class="side-item">
                <img src="./assets/side-img4.png" alt="" @click="showMarList">
                <span>路线</span>
            </li>
            <li class="side-item">
                <img src="./assets/side-img5.png" alt="" @click="isFeedback=true">
                <span>反馈</span>
            </li>
        </ul>
        <ul class="side side-left">
            <li class="side-item" @click="isInfo=true">
                <img src="./assets/side-img3.png" alt="">
                <span>介绍</span>
            </li>
        </ul>
        <ul class="side side-right-bottom">
            <li class="side-item" @click="getLocation">
                <img src="./assets/side-img6.png" alt="">
                <span>定位</span>
            </li>
        </ul>
        <div class="vr-box" v-show="isVr">
            <iframe :src="vrUrl" frameborder="0"></iframe>
            <div @click="hideVr"></div>
        </div>
        <transition name="slide-fade">
            <div class="marker-info" v-show="isCon">
                <div class="info-top-sign"></div>
                <div class="info-area">
                    <div class="info-area-img">
                        <img :src="markerInfo.photos?markerInfo.photos:'https://rocphd-1.rocphd.com/lyct/image/nopic.jpg'" class="info-img-url" alt="">
                        <img :src="playBtn" class="info-img-play" alt="" v-if="markerInfo.audio" @click="playAudio">
                    </div>
                    <div class="info-area-content">
                        <h3>{{markerInfo.mapPointName}}</h3>
                        <p>{{markerInfo.pointIntroduce?markerInfo.pointIntroduce:"暂无简介!"}}</p>
                    </div>
                </div>
                <div class="info-menu" :style="(markerInfo.pointIntroduce || markerInfo.parkId)?'justify-content: space-between':'justify-content: center'">
                    <div class="info-menu-btn mt1" v-if="markerInfo.pointIntroduce || markerInfo.parkId" @click="showContent1(markerInfo.mapPointId,markerInfo.parkId)"><img src="https://rocphd-1.rocphd.com/lyct/image/pop1.png"
                                                                             alt="">查看详情
                    </div>
                    <div class="info-menu-btn mt2" v-if="markerInfo.panoramaUrl" @click="showVr"><img
                            src="https://rocphd-1.rocphd.com/lyct/image/pop3.png" alt="">全景游览
                    </div>
                    <div class="info-menu-btn mt3" @click="gotoNav"><img src="https://rocphd-1.rocphd.com/lyct/image/pop4.png" alt="">去这里</div>
                </div>
            </div>
        </transition>
        <transition name="slide-left-fade">
            <div class="marker-detail" v-show="showCon">
                <div class="marker-title">
                    <p>{{markerInfo.mapPointName}}</p>
                    <div class="marker-close" @click="showCon=false"></div>
                </div>
                <div class="btn-audio" v-show="markerInfo.audio">
                    <audio ref="audio" :src="markerInfo.audio" @play="play" @pause="pause" autoplay loop controls controlsList="nodownload noplaybackrate"></audio>
                </div>
                <div class="marker-content">
                    <img :src="markerInfo.photos" alt="" v-show="markerInfo.photos">
                    <p>{{markerInfo.pointIntroduce?markerInfo.pointIntroduce:(markerInfo.mapTypeId==6?"":"暂无简介!")}}</p>
                    <div v-if="markerInfo.mapTypeId==6">
                        <div v-if="totalNum!=0">
                            <h2 style="margin-bottom: 10px">数据展示</h2>
                            <div class="data-show-con">
                                <div style="background: #78BBED" class="show-con-item">
                                    <div class="show-key">停车位总数量</div>
                                    <div class="show-value">{{ totalNum }}</div>
                                </div>
                                <div style="background: #E8B439" class="show-con-item">
                                    <div class="show-key">剩余车位数量</div>
                                    <div class="show-value">{{ freeSpaceNum }}</div>
                                </div>
                                <div :style="'background:'+(freeRate>10?'\#B4D152':'\#ff0000')" class="show-con-item">
                                    <div class="show-key">车位空闲率</div>
                                    <div class="show-value">{{ freeRate }}%</div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <p>暂无数据!</p>
                        </div>
                    </div>
                </div>
                <div class="marker-nav">
                    <div class="btn-nav">
                        <i class="el-icon-position"></i>
                        <span style="margin-left: 5px" @click="gotoNav">去这里</span>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="slide-top-fade">
            <div class="ct-info" v-show="isInfo">
                <div class="ct-info-title">
                    <p>客家首府·大美汀州</p>
                    <div class="ct-info-close" @click="isInfo=false">×</div>
                </div>
                <div class="ct-info-content">
                    <img src="https://www.rocphd.com/cases/fjlyct/img1/info2.jpg">
                    <p>
                        长汀古称“汀州”，简称“汀”，地处福建西部，武夷山脉南麓，是闽粤赣三省边陲要冲。全县辖18个乡（镇）300个村（居），总人口55万，土地面积3104.16平方公里，是典型的“八山一水一分田”山区县，属福建省第五大县。
                    </p>
                    <p>
                        长汀星火燎原、红旗不倒，是光耀神州的著名革命老区、原中央苏区、红军故乡和红军长征主要出发地之一。1931年，周恩来同志赞叹道“汀州的繁盛，简直为全国苏区之冠”。1937年，朱德同志评价“长汀，果然是中国革命历史的一个转折点”。
                    </p>
                    <p>长汀历史悠久、底蕴深厚，是久负盛名的国家历史文化名城。</p>
                    <p>长汀文化厚重、璀璨多姿，是享誉中外的世界客家首府。长汀山水秀美、景色怡人，是全国生态文明建设示范县和“绿水青山就是金山银山”实践创新基地，是宜居宜业宜游的生态家园。</p>
                </div>
            </div>
        </transition>
        <transition name="slide-top-fade">
            <div class="ct-info" v-show="isMarList">
                <div class="ct-info-title">
                    <p>推荐路线</p>
                    <div class="ct-info-close" @click="isMarList=false">×</div>
                </div>
                <div class="ct-info-content">
                    <div v-for="(item,i) in playPlan" :key="i" @click="showXian(i)">
                        <div class="ct-info-item">
                            <span>{{i+1}}</span>
                            <p>{{item.routeName}}</p>
                        </div>
                        <div style="margin-bottom: 20px" v-html="item.remark">
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="slide-top-fade">
            <div class="ct-info" v-show="isFeedback">
                <div class="ct-info-title">
                    <p>意见反馈</p>
                    <div class="ct-info-close" @click="isFeedback=false">×</div>
                </div>
                <div class="ct-info-content">
                    <div class="h6">反馈类型</div>
                    <el-select v-model="value" placeholder="请选择">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.value"
                                :value="item.id">
                        </el-option>
                    </el-select>
                    <div class="h6">反馈信息</div>
                    <el-input v-model="feedCon" type="textarea" :rows="5" placeholder="请输入您的反馈信息"></el-input>
                    <div class="h6">上传图片<span style="font-size: 12px;color: gray">（最多上传3张）</span></div>
                    <el-upload action="#" list-type="picture-card" :auto-upload="false" :limit="3"
                               accept="image/*" multiple :on-change="handleAddImge">
                        <i slot="default" class="el-icon-plus"></i>
                        <div slot="file" slot-scope="{file}">
                            <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                            <span class="el-upload-list__item-actions">
                                <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                                  <i class="el-icon-delete"></i>
                                </span>
                            </span>
                        </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                    <div class="h6">联系方式</div>
                    <el-input v-model="feedName" type="text" placeholder="请输入联系人" :rules="[{required: true, message: '不能为空'}]"></el-input>
                    <el-input v-model="feedTel" type="number" placeholder="请输入联系号码"></el-input>
                    <el-button class="feed-btn" type="primary" @click="addFeed" round>提交</el-button>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div class="nav-bg" v-if="isNavList" @click="isNavList=false">
                <div class="nav-box">
                    <div class="nav-item" @click="openWxLocation(markerInfo.pointLatitude,markerInfo.pointLongitude,markerInfo.mapPointName,markerInfo.addr)">腾讯地图</div>
                    <div class="nav-item" @click="openGdLocation(markerInfo.pointLatitude,markerInfo.pointLongitude,markerInfo.mapPointName,markerInfo.addr)">高德地图</div>
<!--                    <div class="nav-item" @click="openBdLocation(markerInfo.pointLatitude,markerInfo.pointLongitude,markerInfo.mapPointName,markerInfo.addr)">百度地图</div>-->
                    <div class="nav-item" style="margin-top: 5px" @click="isNavList=false">关闭</div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import 'leaflet/dist/leaflet.css'
    import L from 'leaflet'
    import icon1 from "./assets/yuyan1.png";
    import icon11 from "./assets/yuyan2.png";
    import playBtn from "./assets/play.png";
    import pauseBtn from "./assets/pause.png";
    import "leaflet.markercluster/dist/MarkerCluster.css";
    import "leaflet.markercluster/dist/MarkerCluster.Default.css";
    import "leaflet.markercluster";
    import "./utils/L.TileLayer.Zoomify";
    import wx from "weixin-js-sdk";
    import { wgs84_to_gcj02 } from "./utils/wgs84_to_gcj02";

    export default {
        name: "app",
        data() {
            return {
                map: null,
                isFind: true,
                corner1: L.latLng(26.1346360473996, 115.971271991756),
                corner2: L.latLng(25.1871102652322, 116.717550596236),
                center: [25.841014, 116.358507],
                zoom: 11,
                minZoom: 10,
                maxZoom: 13,
                zoomControl: false,
                attributionControl: false,
                myIcon: L.icon({
                    iconUrl: icon1,
                    iconSize: [30, 30],
                    iconAnchor: [30, 30],
                    popupAnchor: [-3, -30],
                    markerClusterLayer: [],
                }),
                myIcon1: L.icon({
                    iconUrl: icon11,
                    iconSize: [36, 36],
                    iconAnchor: [36, 36],
                    popupAnchor: [-3, -30],
                    markerClusterLayer: [],
                }),
                myDeIcon: L.icon({
                    iconUrl: icon1,
                    iconSize: [30, 30],
                    iconAnchor: [30, 30],
                    popupAnchor: [-3, -30],
                    markerClusterLayer: [],
                }),
                myDeIcon1: L.icon({
                    iconUrl: icon11,
                    iconSize: [36, 36],
                    iconAnchor: [36, 36],
                    popupAnchor: [-3, -30],
                    markerClusterLayer: [],
                }),
                imageBounds: [[26.1346360473996, 115.971271991756], [25.1871102652322, 116.717550596236]],
                active: 0,
                defaultColor: "rgb(72, 72, 72)",
                markerData: [],
                searchData: [],
                markerDetail: [],
                layers: [],
                myGroup: [],
                playBtn: playBtn,
                isInfo: false,
                isMarList: false,
                isCon: false,
                showCon: false,
                isVr: false,
                isFeedback: false,
                markerInfo: [],
                isSearch: false,
                content: "",
                activeName: "0",
                playPlan: [],
                pointMarker: [],
                pointGrop: [],
                pointXian: [],
                vrUrl: '',
                options: [{
                    id: 1,
                    value: '景区问题'
                }, {
                    id: 2,
                    value: '食品卫生问题'
                }, {
                    id: 3,
                    value: '食品质量问题'
                }, {
                    id: 4,
                    value: '导游问题'
                }, {
                    id: 5,
                    value: '旅行社问题'
                }, {
                    id: 6,
                    value: '酒店问题'
                }, {
                    id: 7,
                    value: '其他问题'
                }, {
                    id: 8,
                    value: '需求建议'
                }],
                value: '',
                feedCon: '',
                feedName: '',
                feedTel: '',
                isNavList: false,
                idx: 0,
                nameFormat: "{x}_{y}",
                ext: ".png",
                oriLong: '',
                oriLat: '',
                isFirstLocation: false,
                dialogImageUrl: '',
                dialogVisible: false,
                disabled: false,
                fileList: [],
                uploadList: [],
                totalNum: 0,
                freeSpaceNum: 0
            }
        },
        computed: {
            freeRate(){
                return (this.freeSpaceNum/this.totalNum*100).toFixed(2);
            }
        },
        created() {
            this.getQueryParam('type');
            this.getLocation();
            this.getData();
            this.getWxConfig();
        },
        mounted() {
            this.initMap();
        },
        methods: {
            getData() {
                let num = this.idx;
                const data = {
                    "mapPointName": "",
                    "fromType": 1
                };
                this.request.post("https://lyct.newpais.com/zhly/api/mapPoint/pointlist", data).then((res) => {
                    // console.log("定位的坐标为："+this.oriLong+"::"+this.oriLat);
                    if(this.oriLong && this.oriLat){
                        this.markerData = this.searchData = res.data.map(item => ({
                            ...item,
                            mapPointList: item.mapPointList.map(point => ({
                                ...point,
                                distance: (this.map.distance(L.latLng(this.oriLong,this.oriLat),L.latLng(point.pointLongitude,point.pointLatitude))/1000).toFixed(2)
                            }))
                        }));
                        this.markerData.forEach(item => {
                            item.mapPointList.sort((a,b) => a.distance - b.distance)
                        })
                    }else{
                        this.markerData = this.searchData = res.data;
                    }
                    let index = this.markerData.findIndex(item => item.mapTypeId == num);
                    if(index == -1){
                        index = 0;
                    }
                    this.selectSort(index);
                    // this.markerDetail = res.data[num].mapPointList;
                    // this.setMyIcon(num);
                    // console.log(this.markerData);//获取到的点位列表
                    // console.log(this.markerDetail);
                    // this.initMarker(this.markerDetail);
                })
            },
            getQueryParam(name) {
                const url = window.location.search;
                let reg = new RegExp(name + '=(.*)');
                let res = url.match(reg);
                if(res){
                    // console.log(res[1]);
                    this.idx = res[1];
                }
            },
            initMap() {
                const that = this;
                const bounds = L.latLngBounds(this.corner1, this.corner2);
                this.map = L.map('map', {
                    center: this.center,
                    zoom: this.zoom,
                    minZoom: this.minZoom,
                    maxZoom: this.maxZoom,
                    zoomControl: this.zoomControl,
                    attributionControl: this.attributionControl,
                    maxBounds: bounds
                });
                L.tileLayer('https://rocphd-1.rocphd.com/lyct/tiles/{z}/{x}_{y}.png').addTo(this.map);
                // L.tileLayer(
                //     "https://webrd01.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=7&x={x}&y={y}&z={z}",
                // ).addTo(this.map);
                this.map.addEventListener("click", (e) => {
                    // console.log(e.latlng);
                    that.isCon = false;
                    this.$refs.audio.pause();
                    that.layers.forEach((layer,i) => {
                        if(this.markerDetail[i].audio!=null){
                            layer.setIcon(that.myDeIcon);
                        }else{
                            layer.setIcon(this.myIcon);
                        }
                    })
                });
            },
            initMarker(data) {
                const that = this;
                let clickedMarker; // 用于跟踪被点击的标记点
                data.forEach((item) => {
                    let marker;
                    if (item.mapPointName != null) {
                        if(this.idx==0){
                            if(item.audio!=null){
                                marker = L.marker([item.markLatitude, item.markLongitude], {
                                    icon: this.myDeIcon,
                                    title: "",
                                    draggable: false//设置标记点是否可以拖动
                                }).bindTooltip(item.mapPointName, {
                                    offset: L.point(-13, 12),
                                    direction: 'center',
                                    permanent: true//是否永久性的打开
                                }).openTooltip()
                            }else{
                                marker = L.marker([item.markLatitude, item.markLongitude], {
                                    icon: this.myIcon,
                                    title: "",
                                    draggable: false//设置标记点是否可以拖动
                                }).bindTooltip(item.mapPointName, {
                                    offset: L.point(-13, 12),
                                    direction: 'center',
                                    permanent: true//是否永久性的打开
                                }).openTooltip()
                            }
                        }else{
                            marker = L.marker([item.markLatitude, item.markLongitude], {
                                icon: this.myIcon,
                                title: "",
                                draggable: false//设置标记点是否可以拖动
                            }).bindTooltip(item.mapPointName, {
                                offset: L.point(-13, 12),
                                direction: 'center',
                                permanent: true//是否永久性的打开
                            })
                            .openTooltip()
                        }
                    } else {
                        marker = L.marker([item.markLatitude, item.markLongitude], {
                            icon: this.myIcon,
                            title: "",
                            draggable: false//设置标记点是否可以拖动
                        })
                    }
                    marker.addEventListener("click", () => {
                        this.$refs.audio.pause();
                        if(item.audio!=null){
                            if (marker === clickedMarker) {
                                // 如果被点击的标记点是之前点击过的标记点，则切换其图标
                                marker.setIcon(this.myDeIcon1); // 切换到另一个图标
                            } else {
                                // 如果被点击的标记点不是之前点击过的标记点，则将所有标记点恢复为默认图标
                                that.layers.forEach((layer,i) => {
                                    // layer.setIcon(that.myDeIcon); // 恢复为默认图标
                                    if(data[i].audio==null){
                                        layer.setIcon(that.myIcon);
                                    }else{
                                        layer.setIcon(that.myDeIcon);
                                    }
                                });
                                clickedMarker = marker; // 更新被点击的标记点
                                marker.setIcon(this.myDeIcon1); // 切换到另一个图标
                            }
                        }else{
                            if (marker === clickedMarker) {
                                // 如果被点击的标记点是之前点击过的标记点，则切换其图标
                                marker.setIcon(this.myIcon1); // 切换到另一个图标
                            } else {
                                // 如果被点击的标记点不是之前点击过的标记点，则将所有标记点恢复为默认图标
                                that.layers.forEach((layer,i) => {
                                    if(data[i].audio==null){
                                        layer.setIcon(that.myIcon);
                                    }else{
                                        layer.setIcon(that.myDeIcon);
                                    }
                                });
                                clickedMarker = marker; // 更新被点击的标记点
                                marker.setIcon(this.myIcon1); // 切换到另一个图标
                            }
                        }
                        that.handlerMarker(item.mapPointId)
                    });
                    this.layers.push(marker);
                })
                // 点聚合组合
                // this.markerClusterLayer.addLayers(this.layers);
                this.myGroup = L.layerGroup(this.layers);
                this.map.addLayer(this.myGroup);
            },
            handlerMarker(id) {
                const data = {
                    mapPointId: id
                };
                this.request.post("https://lyct.newpais.com/zhly/api/mapPoint/pointIntroduce", data).then((res) => {
                    this.markerInfo = res.data;
                    this.isCon = true;
                    // console.log(this.markerInfo)
                })
            },
            //清空组里面所有的标记点
            clearMarker() {
                this.map.removeLayer(this.myGroup);
                // this.markerClusterLayer.clearLayers();//清楚所有标记点
            },
            selectSort(i) {
                this.idx = i;
                this.active = i;
                this.showCon = false;
                this.layers = [];
                this.setMyIcon(i);
                this.clearMarker();
                // this.map.flyTo(this.center, this.zoom);
                this.markerDetail = this.markerData[i].mapPointList;
                this.initMarker(this.markerDetail);
            },
            setMyIcon(i){
                this.myIcon = L.icon({
                    iconUrl: this.markerData[i].defaultIcon,
                    iconSize: [30, 30],
                    iconAnchor: [30, 30],
                    popupAnchor: [-3, -30],
                    markerClusterLayer: [],
                })
                this.myIcon1 = L.icon({
                    iconUrl: this.markerData[i].selectedIcon,
                    iconSize: [36, 36],
                    iconAnchor: [36, 36],
                    popupAnchor: [-3, -30],
                    markerClusterLayer: [],
                })
            },
            showContent() {
                this.showCon = true;
            },
            showContent1(id,parkId) {
                const data = {
                    mapPointId: id
                };
                this.request.post("https://lyct.newpais.com/zhly/api/mapPoint/pointIntroduce", data).then((res) => {
                    this.markerInfo = res.data;
                    this.showCon = true;
                    this.totalNum = 0;
                    this.freeSpaceNum = 0;
                    if(parkId!=null){
                        const data1 = {
                            parkId: parkId
                        };
                        this.request.post("https://lyct.newpais.com/zhly/api/mapPoint/queryParkInfo", data1).then((res1) => {
                            const res2 = JSON.parse(res1.data);
                            this.totalNum = res2.totalNum;
                            this.freeSpaceNum = res2.freeSpaceNum;
                            // console.log(res2);
                        })
                    }
                })
            },
            showVr() {
                this.$refs.audio.pause();
                this.vrUrl = this.markerInfo.panoramaUrl;
                this.isVr = true;
            },
            hideVr(){
                this.vrUrl = 'https://www.newpais.com/no.html';
                this.isVr = false;
            },
            play(){
                this.playBtn=pauseBtn;
            },
            pause(){
                this.playBtn=playBtn;
            },
            playAudio() {
                if (this.$refs.audio.paused) {
                    this.playBtn=pauseBtn;
                    this.$refs.audio.play(true);
                } else {
                    this.playBtn=playBtn;
                    this.$refs.audio.pause();
                }
            },
            showSearch(){
                this.isSearch = true;
                if(this.oriLong && this.oriLat){
                    this.searchData = this.markerData.map(item => ({
                        ...item,
                        mapPointList: item.mapPointList.map(point => ({
                            ...point,
                            distance: (this.map.distance(L.latLng(this.oriLong,this.oriLat),L.latLng(point.pointLongitude,point.pointLatitude))/1000).toFixed(2)
                        }))
                    }));
                    this.searchData.forEach(item => {
                        item.mapPointList.sort((a,b) => a.distance - b.distance)
                    })
                }else{
                    this.searchData = this.markerData;
                }
                this.$refs.search.focus();
            },
            hideSearch(){
                this.content = "";
                this.isSearch = false;
            },
            goBuy(){
                var shopUrl = "https://m.ctrip.com/webapp/tnt/list?pagetype=city&citytype=dt&pshowcode=Ticket2&id=1445095&name=%E9%95%BF%E6%B1%80&ctm_ref=vactang_page_8349";
                window.open(shopUrl)
            },
            getWxConfig(){
                const url = encodeURIComponent(location.href.split('#')[0]);
                // console.log(url);
                this.request.get("https://wx.newpais.com/wxtool?currentUrl="+url).then((res) => {
                    // console.log(res);
                    wx.config({
                        debug: false,
                        appId: res.appId,
                        timestamp: res.timestamp,
                        nonceStr: res.nonceStr,
                        signature: res.signature,
                        jsApiList: [
                            'openLocation',
                        ]
                    })
                })
            },
            gotoNav(){
                const that = this;
                this.$refs.audio.pause();
                let ua = navigator.userAgent.toLowerCase();
                let isWeiXin = ua.indexOf('micromessenger') !== -1;
                if(!isWeiXin){
                    this.isNavList=true;
                }else{
                    wx.ready(function () {
                        wx.openLocation({
                            latitude: Number(that.markerInfo.pointLatitude), // 纬度，浮点数，范围为90 ~ -90
                            longitude: Number(that.markerInfo.pointLongitude), // 经度，浮点数，范围为180 ~ -180。
                            name: that.markerInfo.mapPointName, // 位置名
                            address: that.markerInfo.addr, // 地址详情说明
                            scale: 15, // 地图缩放级别,整型值,范围从1~28。默认为最大
                            infoUrl: '', // 在查看位置界面底部显示的超链接,可点击跳转
                        });
                    });
                }
            },
            //腾讯导航
            openWxLocation(lat, lon, title, addr) {
                var mapUrl = "https://apis.map.qq.com/uri/v1/marker?marker=coord:" + lat + "," + lon + ";title:" + title + ";addr:" + addr + "&referer=yellowpage";
                window.open(mapUrl)
            },
            //高德导航
            openGdLocation(lat, lon, title, addr) {
                var mapUrl = "https://uri.amap.com/marker?position=" + lon + "," + lat + "&name=" + title + "&src=mypage&coordinate=gaode&callnative=0";
                window.open(mapUrl)
            },
            handleClick(tab, event) {
                // console.log(tab, event);
            },
            startFind() {
                const data = {
                    mapPointName: this.content
                };
                this.request.post("https://lyct.newpais.com/zhly/api/mapPoint/pointlist", data).then((res) => {
                    if(this.oriLong && this.oriLat){
                        this.searchData = res.data.map(item => ({
                            ...item,
                            mapPointList: item.mapPointList.map(point => ({
                                ...point,
                                distance: (this.map.distance(L.latLng(this.oriLong,this.oriLat),L.latLng(point.pointLongitude,point.pointLatitude))/1000).toFixed(2)
                            }))
                        }));
                        this.searchData.forEach(item => {
                            item.mapPointList.sort((a,b) => a.distance - b.distance)
                        })
                    }else{
                        this.searchData = res.data;
                    }
                })
            },
            showXian(num){
                this.isMarList = false;
                this.pointGrop = [];
                this.pointMarker = [];
                this.clearMarker();
                // console.log(this.playPlan[num].xian);
                const con = this.playPlan[num].pointList;
                con.forEach((item,i) => {
                    let a = [item.markLatitude,item.markLongitude];
                    this.pointGrop.push(a);
                    let myIcon1 = L.divIcon({className: 'my-div-icon',html: '<span>'+(i+1)+'</span>'});
                    let p = L.marker([item.markLatitude, item.markLongitude], {icon: myIcon1}).addTo(this.map);
                    this.pointMarker.push(p);
                });
                // 创建轨迹线
                let poy = L.polyline(this.pointGrop).addTo(this.map);
                this.pointMarker.push(poy);
                this.myGroup = L.layerGroup(this.pointMarker);
                this.map.addLayer(this.myGroup);
            },
            showMarList(){
                this.isMarList = true;
                const data = {
                    "pageNum": 1,
                    "pageSize": 10
                }
                this.request.post("https://lyct.newpais.com/zhly/api/recommendRoute/list", data).then((res) => {
                    // console.log(res.data);
                    this.playPlan = res.data;
                })
            },
            getLocation(){
                const that = this;
                var str="不支持定位功能";
                if (navigator.geolocation){
                    navigator.geolocation.getCurrentPosition( function (position) {
                        const latitude = position.coords.latitude;//获取纬度
                        const longitude = position.coords.longitude;//获取经度
                        let xx = wgs84_to_gcj02(longitude,latitude);
                        that.oriLong = xx[0];
                        that.oriLat = xx[1];
                        if(that.imageBounds[1][0] < that.oriLat && that.oriLat < that.imageBounds[0][0]
                            && that.imageBounds[0][1] < that.oriLong && that.oriLong < that.imageBounds[1][1]){
                            if(that.isFirstLocation){
                                that.$message({
                                    message: '获取定位成功！',
                                    duration: 1500,
                                    type: 'success'
                                });
                            }else{
                                that.isFirstLocation = true;
                            }
                        }else{
                            that.$message.error('您不在地图范围内！');
                        }
                    });
                }else{
                    alert(str);
                }
            },
            showTip(){
                this.$message('本版本为手绘地图的初步试运行版本，部分内容可能尚未完善。对于可能出现的错误或不便之处，我们深感抱歉，并诚挚地请求广大用户的谅解。我们会不断进行后续的修正与优化工作，确保为大家提供更加准确、便捷的服务。');
            },
            handleRemove(file) {
                // console.log(file);
            },
            handleAddImge(file, fileList){
                this.fileList = fileList.slice(-3);
                // let newFileList = fileList.slice(-3);
                // this.fileList = newFileList.map(item => item.url);
            },
            async addFeed(){
                // console.log(this.fileList)
                this.uploadList = [];
                const promises = this.fileList.map(item => {
                    return new Promise((resolve, reject) => {
                        let reader = new FileReader();
                        reader.readAsDataURL(item.raw);
                        reader.onload = (e) => {
                            this.uploadList.push(e.target.result.split(",")[1]);
                            resolve(); // 文件读取完成后解析promise
                        }
                        reader.onerror = reject; // 如果读取失败，则拒绝promise
                    });
                })
                // 等待所有文件读取完成
                await Promise.all(promises);

                if(this.value==""){
                    this.$message({
                        message: '反馈类型未选择！',
                        type: 'warning'
                    });
                }else{
                    if(this.feedCon=='' || this.feedName=='' || this.feedTel==''){
                        this.$message({
                            message: '填写信息不能为空',
                            type: 'warning'
                        });
                    }else{
                        const data = {
                            userId: '2024001',
                            recommendType: this.value,
                            recommendContent: this.feedCon,
                            linkman: this.feedName,
                            contactWay: this.feedTel,
                            imgFiles: this.uploadList
                        };
                        // console.log(data);
                        this.request.post("https://lyct.newpais.com/zhly/api/recommend/add", data).then((res) => {
                            // console.log(res);
                            if(res.code === 200){
                                // console.log(res);
                                this.$message({
                                    message: '反馈提交成功！',
                                    type: 'success'
                                });
                            }
                        })
                    }
                }
            }
        }
    }
</script>

<style>
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    #app {
        width: 100vw;
        height: 100vh;
    }

    #map {
        width: 100%;
        height: 100%;
    }

    .leaflet-tooltip {
        color: #6b422b;
        background: #fff;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        box-shadow: 0 3px 6px 0 #0003;
        border-radius: 15px;
        padding: 2px 10px;
        border: 1px solid red;
    }

    .top-search-box {
        position: fixed;
        text-align: center;
        top: 10px;
        width: 100%;
        z-index: 999;
    }

    .top-search-input {
        position: relative;
        width: calc(100% - 32px);
        height: 40px;
        border-radius: 8px;
        box-shadow: 0 4px 5px 0 #768ba717;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        color: #000000d9;
        /*display: inline-block;*/
        display: inline-flex;
        font-size: 14px;
        line-height: 1.5715;
        min-width: 0;
        padding: 4px 11px;
        transition: all .3s;
    }

    .ant-input-prefix {
        cursor: pointer;
        margin-right: 8px;
        align-items: center;
        display: flex;
        flex: none;
    }

    .ant-input-prefix img {
        margin-left: 5px;
    }

    input.ant-input {
        border: none;
        outline: none;
        padding: 0;
    }

    .map-toolbar {
        position: fixed;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0 auto;
        /*padding-bottom: 10px;*/
        width: 100%;
        max-width: 570px;
        padding-bottom: env(safe-area-inset-bottom);
        -webkit-user-select: none;
        user-select: none;
        z-index: 999;
        background: #fff;
        border-radius: 10px 10px 0 0;
        box-shadow: 0 2px 10px 0 #00000038;
    }

    .toolbar-bottom {
        width: 100%;
        overflow: scroll;
    }

    .toolbar-content {
        display: flex;
        flex-wrap: nowrap;
    }

    .toolbar-item {
        display: inline-block;
        -ms-overflow-style: none;
        color: #c6c6c6;
        cursor: pointer;
        font-size: 14px;
        height: 65px;
        margin: 5px 0;
        min-width: 80px;
        padding: 0 12px;
        text-align: center;
        white-space: nowrap;
        pointer-events: auto;
    }

    .toolbar-item img {
        display: block;
        height: 24px;
        margin: 8px auto 5px;
        width: 24px;
    }

    /* 设置持续时间和动画函数 */
    .slide-bottom-fade-enter-active {
        transition: all .3s ease;
    }

    .slide-bottom-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-bottom-fade-enter, .slide-bottom-fade-leave-to
    {
        transform: translateY(20px);
        opacity: 0;
    }

    .slide-left-fade-enter-active {
        transition: all .5s ease;
    }

    .slide-left-fade-leave-active {
        transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-left-fade-enter, .slide-left-fade-leave-to
    {
        transform: translateX(100%);
        opacity: 0;
    }

    .slide-top-fade-enter-active {
        transition: all .5s ease;
    }

    .slide-top-fade-leave-active {
        transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-top-fade-enter, .slide-top-fade-leave-to
    {
        transform: translateY(-100%);
        opacity: 0;
    }

    .side {
        background: #fff;
        box-shadow: 0 2px 5px 0 #76767633;
        font-size: 20px;
        position: fixed;
        text-align: center;
        border-radius: 9px;
        padding: 6px 0;
        z-index: 999;
    }

    .side-right {
        right: 16px;
        top: 80px;
    }

    .side-left {
        left: 16px;
        top: 80px;
    }

    .side-right-bottom {
        right: 16px;
        bottom: 100px;
    }

    .side-item {
        box-sizing: border-box;
        color: #888;
        cursor: pointer;
        display: block;
        font-size: 11px;
        text-align: center;
        width: 36px;
    }

    .side-item img {
        border-radius: 50%;
        display: block;
        height: 27px;
        margin: 0 auto 2px;
        width: 27px;
    }

    .side-item span {
        zoom: .9;
        word-wrap: break-word;
        display: inline-block;
        text-align: center;
        width: 35px;
    }

    .marker-info {
        position: absolute;
        bottom: 15px;
        width: calc(100% - 40px);
        height: max-content;
        background: #fff;
        padding-bottom: 15px;
        left: 20px;
        z-index: 1001;
        border-radius: 8px;
        border: 1px solid #d9d9d9;
        box-shadow: 0 4px 5px 0 #768ba717;
    }

    .info-top-sign {
        width: 50px;
        height: 5px;
        border-radius: 20px;
        background: #D9DDE0;
        margin: 15px auto;
    }

    .info-area {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        padding: 0 15px;
    }

    .info-area-img{
        position: relative;
        width: 100px;
        height: 60px;
        margin-right: 10px;
    }

    .info-img-url {
        width: 100px;
        height: 60px;
        border-radius: 6px;
    }

    .info-img-play{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 20px;
        height: 20px;
    }

    .info-area-content h3 {
        font-size: 14px;
    }

    .info-area-content p {
        text-align: justify;
        margin: 5px 0;
        display: -webkit-box; /*弹性伸缩盒子*/
        -webkit-box-orient: vertical; /*子元素垂直排列*/
        font-size: 12px;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .info-menu {
        width: 100%;
        padding: 0 15px;
        margin-top: 12px;
        display: flex;
        align-items: center;
    }

    .info-menu-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2e7bff;
        color: white;
        font-size: 14px;
        padding: 7px 10px;
        border-radius: 20px;
    }

    .info-menu-btn img {
        width: 18px;
        height: 18px;
        margin-right: 5px;
    }

    .mt1 {
        background: #F6B534;
    }

    .mt2 {
        background: #64D3B8;
    }

    .mt3 {
        background: #60A6EC;
    }

    .sel-marker{
        z-index: 200;
    }

    .search-area{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background: #F9FBFF;
        overflow: hidden;
    }

    .search-list{
        position: absolute;
        top: 60px;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .search-item{
        box-sizing: border-box;
        padding: 8px 15px;
        display: flex;
        font-size: 13px;
        display: flex;
        justify-content: space-between;
        justify-items: center;
    }

    .search-left{
        display: flex;
        flex-direction: column;
    }

    .distance{
        font-size: 12px;
        color: gray;
        margin-top: 2px;
    }

    .search-name{
        display: flex;
        align-items: center;
    }

    .search-btn{
        box-sizing: border-box;
        width: 70px;
        height: 27px;
        line-height: 25px;
        text-align: center;
        border-radius: 20px;
        color: black;
        font-size: 12px;
    }

    .el-tabs{
        position: relative;
        width: 100%;
        height: 100%;
    }

    .el-tabs__nav-prev,
    .el-tabs__nav-next{
        display: none;
    }

    .el-tabs__nav-scroll{
        overflow: scroll!important;
    }

    .el-tabs__content{
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: calc(100% - 60px);
        padding-bottom: 20px;
        overflow: scroll!important;
    }

    .search-item img{
        width: 20px;
        height: 20px;
        margin-right: 8px;
    }

    .el-tabs__active-bar{
        height: 5px;
        border-radius: 10px;
    }

    .marker-detail,
    .ct-info {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1001;
        background: #F9FBFF;
        overflow-y: scroll;
    }

    .marker-title,
    .ct-info-title {
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex: 0 1;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;
        padding: 16px 30px;
        background: white;
    }

    .marker-title p,
    .ct-info-title p {
        width: 100%;
        text-align: center;
        font-size: 16px;
        line-height: 22px;
    }

    .marker-close {
        position: absolute;
        left: 0;
        top: calc(50% - 12px);
        width: 22px;
        height: 24px;
        background-color: #ff7875;
        background-image: url("./assets/ret.png");
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 15px 15px;
        border-radius: 0 5px 5px 0;
    }

    .ct-info-close{
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 22px;
        line-height: 22px;
    }

    .marker-content,
    .ct-info-content {
        margin-top: 10px;
        width: calc(100% - 40px);
        margin-left: 20px;
        padding: 15px;
        word-wrap: break-word;
        background: #fff;
        border-radius: 8px 8px 0 0;
        box-shadow: 0 4px 10px 0 #0000000f;
        color: #2f2f2f;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 22px;
        margin-bottom: 70px;
        word-break: break-all;
        text-align: justify;
        margin-bottom: 60px;
    }

    .marker-content img,
    .ct-info-content img{
        width: 100%;
        border-radius: 8px;
        margin: 5px 0;
    }

    .data-show-con{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .show-con-item{
        box-sizing: border-box;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
        padding: 10px 0;
        color: white;
    }

    .show-key{
        font-size: 14px;
    }

    .show-value{
        font-size: 20px;
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .el-select{
        width: 100%;
    }

    .ct-info-content .h6{
        font-size: 15px;
        margin: 10px 0 5px 0;
    }

    .ct-info-content input{
        box-sizing: border-box;
        width: 100%;
        height: 35px;
        background: #F9FBFF;
        border: 0px;
        margin: 5px 0;
    }

    .feed-btn{
        width: 100%;
        margin-top: 30px!important;
    }

    .ct-info-content textarea {
        box-sizing: border-box;
        width: 100%;
        background: #F9FBFF;
        border: 0px;
    }

    .ct-info-item{
        display: flex;
        margin-bottom: 5px;
        font-size: 14px;
        justify-content: start;
        align-items: center;
    }

    .ct-info-item span{
        display: block;
        width: 20px;
        height: 20px;
        background: #2e7bff;
        border-radius: 50%;
        margin-right: 5px;
        color: white;
        line-height: 20px;
        text-align: center;
    }

    .ct-info-item p{
        width: calc(100% - 30px);
    }

    .my-div-icon{
        box-sizing: border-box;
        margin-top: -13px!important;
        width: 25px!important;
        height: 25px!important;
        border-radius: 50%;
        background: white;
        border: 2px solid #2e7bff;
    }

    .my-div-icon span{
        display: block;
        text-align: center;
        line-height: 25px;
    }

    .marker-nav {
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 54px;
        margin: 0 auto;
        background-image: linear-gradient(179deg, #fff0, #fff);
    }

    .btn-nav {
        display: flex;
        width: 226px;
        height: 36px;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(180deg, #4fa1e5, #7fc3ff);
        border-radius: 18px;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        margin: 0 auto;
        text-align: center;
    }

    .btn-audio {
        width: calc(100% - 40px);
        margin-top: 10px;
        margin-left: 20px;
        display: block;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 4px 10px 0 #0000000f;
        padding: 15px;
    }

    .btn-audio audio {
        width: 100%;
    }

    .vr-box {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1005;
        width: 100%;
        height: 100%;
        background: black;
    }

    .vr-box iframe {
        width: 100%;
        height: 100%;
    }

    .vr-box div {
        height: 24px;
        position: absolute;
        left: 0;
        top: 100px;
        background-color: #ff7875;
        background-image: url("./assets/ret.png");
        width: 22px;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 15px 15px;
        border-radius: 0 5px 5px 0;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

    .nav-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2000;
        background: rgba(0,0,0,0.5);
    }

    .nav-box{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: white;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .nav-item{
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        font-size: 13px;
        text-align: center;
        line-height: 50px;
        border-bottom: 1px solid #F0F0F0;
    }

    .tiyan{
        position: fixed;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 9999;
        background: red;
        color: white;
        padding: 10px 5px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        display: none;
    }

    .vertical {
        writing-mode: vertical-rl; /* 从右到左 */
        text-orientation: upright; /* 保持正常显示 */
        letter-spacing: 3px;
    }

    .el-upload--picture-card{
        width: 80px!important;
        height: 80px!important;
        line-height: 80px!important;
    }

    .el-upload-list--picture-card .el-upload-list__item{
        width: 80px!important;
        height: 80px!important;
    }

</style>
